import React, { useState, useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom"; // Import Redirect
import { Container } from "reactstrap";

import Loading from "./pages/components/Loading";
import NavBar from "./pages/components/NavBar";
import Footer from "./pages/components/Footer";
import Profile from "./pages/profile/Profile";
import Training from "./pages/views/Training";
import ExercisesPage from "./pages/views/Exercises";
import ThreadingPage from "./pages/exercises/2_Threading";
import WeavingPage from "./pages/exercises/4_Weaving";

import SandboxPage from "./pages/sandbox/Sandbox";
import PaymentsPage from "./pages/views/Payments";
import ProductsPage from "./pages/views/Products";
import Home from "./pages/home/Home";
import Welcome from "./pages/home/Welcome";
import MeetMiaPage from "./pages/exercises/0_MeetMia";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import { GettingStartedPage } from "./pages/exercises/1_GettingStarted";
import "./App.css";
import initFontAwesome from "./utils/initFontAwesome";
import { ConnectedThreadsPage } from "./pages/exercises/3_ConnectedThreads";
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '73fa7830-91c6-4963-b956-604d1243b9a7',
    clientToken: 'pub1cfc1c373aef55e97fbe3ac6233d8c14',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'broadlysocial-app',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

initFontAwesome();

const App = () => {
  const [jwtToken, setJwtToken] = useState("asdf");
  const { isAuthenticated, loginWithRedirect, isLoading, error } = useAuth0();
  const [autoPlayAudioPreference, setAudioPref] = useState(false);
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  if (error) {
    return <div>App.js error: {error.message}</div>;
  }

  if (isLoading || !isAuthenticated) {
    return <Loading />;
  }

  const handleTokenChange = (newToken) => {
    setJwtToken(newToken);
  };

  const setAutoPlayAudioPreference = (autoPlayAudioPreference) => {
    setAudioPref(autoPlayAudioPreference);
  };

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar
          jwtToken={jwtToken}
          onTokenChange={handleTokenChange}
          setAutoPlayAudioPreference={setAutoPlayAudioPreference}
        />
        <Container className="flex-grow-1 mt-5">
          <Switch>
            {/* Add a Redirect for the root path */}
            <Route exact path="/" render={() => <Redirect to="/home" />} />

            <Route
              path="/home"
              render={() => (
                <Home jwtToken={jwtToken} onTokenChange={handleTokenChange} />
              )}
            />
            <Route
              path="/welcome"
              render={() => (
                <Welcome
                  jwtToken={jwtToken}
                  onTokenChange={handleTokenChange}
                />
              )}
            />
            <Route
              path="/exercises/meet/mia"
              render={() => (
                <MeetMiaPage
                  jwtToken={jwtToken}
                  onTokenChange={handleTokenChange}
                  autoPlayAudioPreference={autoPlayAudioPreference}
                />
              )}
            />
            <Route
              path="/exercises/start/learn"
              render={() => (
                <GettingStartedPage
                  jwtToken={jwtToken}
                  onTokenChange={handleTokenChange}
                  autoPlayAudioPreference={autoPlayAudioPreference}
                />
              )}
            />
            <Route
              path="/exercises/thread/learn"
              render={() => (
                <ThreadingPage
                  jwtToken={jwtToken}
                  onTokenChange={handleTokenChange}
                  autoPlayAudioPreference={autoPlayAudioPreference}
                />
              )}
            />
            <Route
              path="/exercises/connectedthread/learn"
              render={() => (
                <ConnectedThreadsPage
                  jwtToken={jwtToken}
                  onTokenChange={handleTokenChange}
                  autoPlayAudioPreference={autoPlayAudioPreference}
                />
              )}
            />
            <Route
              path="/exercises/weave/learn"
              render={() => (
                <WeavingPage
                  jwtToken={jwtToken}
                  onTokenChange={handleTokenChange}
                />
              )}
            />
            <Route
              path="/sandbox"
              render={() => (
                <SandboxPage
                  jwtToken={jwtToken}
                  onTokenChange={handleTokenChange}
                />
              )}
            />
            <Route
              path="/exercises"
              render={() => (
                <ExercisesPage
                  jwtToken={jwtToken}
                  onTokenChange={handleTokenChange}
                />
              )}
            />
            <Route
              path="/profile"
              render={() => (
                <Profile token={jwtToken} onTokenChange={handleTokenChange} />
              )}
            />
            <Route
              path="/training"
              render={() => (
                <Training
                  jwtToken={jwtToken}
                  onTokenChange={handleTokenChange}
                />
              )}
            />
            <Route
              path="/payments"
              render={() => (
                <PaymentsPage
                  token={jwtToken}
                  onTokenChange={handleTokenChange}
                />
              )}
            />
            <Route
              path="/products"
              render={() => (
                <ProductsPage
                  token={jwtToken}
                  onTokenChange={handleTokenChange}
                />
              )}
            />
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
