import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import history from "../../utils/history";
import { ENDPOINT } from "../../constants";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";

export const Home = ({ jwtToken, onTokenChange }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [lessonStatus, setLessonStatus] = useState({
    lesson0: false,
    lesson1: false,
    lesson2: false,
    lesson3: false,
    lesson4: false,
  });

  useEffect(() => {
    // Simulated fetch from API
    async function fetchLessonStatus() {
      const mockApiData = {
        // Simulated response data
        lesson0: true,
        lesson1: true, // Example: User has completed Lesson 1
        lesson2: false,
        lesson3: false,
        lesson4: false,
      };
      setLessonStatus(mockApiData);
    }
    fetchLessonStatus();
  }, []);

  // Function to navigate to the threading exercise
  const goToLesson = (path) => {
    history.push(path);
  };
  //

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>Welcome back!</h1>
          <p className="mt-4">
            Dive into the art of social dynamics with BroadlySocial, your go-to
            platform for mastering social skills. From making a great first
            impression to mastering the art of conversation, we provide
            comprehensive training to enhance your social fluency.
          </p>
          <p>
            Begin your journey with our foundational exercise on{" "}
            <strong>threading</strong> — a technique that will transform the way
            you engage in conversations. Discover the power of effective
            communication, build confidence, and connect with others like never
            before.
          </p>
          <div className="mt-4">
          <Table striped>
            <thead>
              <tr>
                <th>Completed</th>
                <th>Lesson</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {[
                { title: "Meet Mia", path: "/exercises/meet/mia", key: "lesson0" },
                { title: "Getting Started", path: "/exercises/start/learn", key: "lesson1" },
                { title: "Endless Ideas - Threading", path: "/exercises/thread/learn", key: "lesson2" },
                { title: "Connected Threads", path: "/exercises/connectedthread/learn", key: "lesson3" },
                { title: "Weaving Conversations", path: "/exercises/weave/learn", key: "lesson4" }
              ].map((lesson, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={lessonStatus[lesson.key]}
                      readOnly
                    />
                  </td>
                  <td>{lesson.title}</td>
                  <td>
                    <Button
                      color="secondary"
                      onClick={() => goToLesson(lesson.path)}
                    >
                      Start: {lesson.title}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(Home, {
  onRedirecting: () => <Loading />,
});
